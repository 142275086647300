import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import Spinner from 'manage-tritag/components/loading/spinner'
import EnhancedTable from 'manage-tritag/components/table/enhanced-table'
import {
  Participant,
  QueryParams,
  useLazyGetParticipantsQuery,
} from 'manage-tritag/services/api/endpoints/participants'
import { calculateAge } from 'manage-tritag/utils/date'
import ParticipantTeamTransferModal from 'manage-tritag/components/modal/participant_team_transfer_modal'
import ParticipantsTableFilter from './participants-table-filter'

interface HeadCell {
  disablePadding: boolean
  id: keyof Participant
  label: string
  numeric: boolean
}

const ParticipantPage = () => {
  const [params, setParams] = useState<QueryParams>({
    offset: 0,
    limit: 100000,
    search: '',
  })
  const [trigger, result] = useLazyGetParticipantsQuery()
  const [playerTransferModal, setPlayerTransferModal] = useState(false)

  const { isLoading, data: participants } = result

  useEffect(() => {
    trigger(params)
  }, [params])

  const headCells: HeadCell[] = [
    {
      id: 'ttr_id',
      numeric: false,
      disablePadding: false,
      label: 'ID',
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'dateOfBirth',
      numeric: false,
      disablePadding: false,
      label: 'Age',
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
  ]
  const bodyCells = ['ttr_id', 'firstName', 'dateOfBirth', 'role']
  return (
    <Layout label1="Participants" url="/teams">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 145px);',
          }}
        >
          <Spinner size={40} color="#008174" />
        </Box>
      ) : (
        participants && (
          <>
            <ParticipantsTableFilter
              params={params}
              setParams={setParams}
              setModal={setPlayerTransferModal}
            />
            <EnhancedTable
              tableTitle="Participants"
              uniqueIdentifier="ttr_id"
              rows={participants.map((participant, index) => ({
                ...participant,
                id: index,
                firstName: `${participant.firstName} ${participant.lastName}`,
                dateOfBirth: calculateAge(new Date(participant.dateOfBirth)),
                lastName: participant.role ? participant.role : 'None',
              }))}
              headCells={headCells}
              bodyCells={bodyCells}
            />
            {playerTransferModal && (
              <ParticipantTeamTransferModal setModal={setPlayerTransferModal} />
            )}
          </>
        )
      )}
    </Layout>
  )
}

export default withAuthenticationRequired(ParticipantPage)
