import {
  Button,
  Fade,
  FormControl,
  InputLabel,
  Modal,
  Select,
  styled,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import _ from 'lodash'

import Spinner from '../loading/spinner'
import {
  Participant,
  useGetParticipantsQuery,
  useLazyGetRegistrationHistoryQuery,
} from '../../services/api/endpoints/participants'
import {
  Team,
  useGetTeamsQuery,
  useTransferParticipantToTeamMutation,
} from '../../services/api/endpoints/teams'
import { noLimitParams } from '../../utils/string'

interface ParticipantTeamTransferModalProps {
  setModal: (value: boolean) => void
  initialParticipantId?: string
  initialFromTeamId?: string
  initialToTeamId?: string
}

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBody = styled('div')`
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  max-width: 550px;
`

const StyledButton = styled(Button)`
  font-size: 0.8rem;
`

const ParticipantTeamTransferModal: FC<ParticipantTeamTransferModalProps> = ({
  setModal,
  initialParticipantId,
  initialFromTeamId,
  initialToTeamId,
}) => {
  const { data: participants } = useGetParticipantsQuery({
    ...noLimitParams,
  })

  const { data: allTeams } = useGetTeamsQuery({
    ...noLimitParams,
    currentSeason: true,
  })

  const [fetchParticipantRegistrations, { data: playerRegistrations }] =
    useLazyGetRegistrationHistoryQuery()

  const [transferParticipantToTeam, { isLoading }] =
    useTransferParticipantToTeamMutation()

  const [playerTeams, setPlayerTeams] = useState<Team[]>([])
  const [participantId, setParticipantId] = useState<string>(
    initialParticipantId || '',
  )
  const [fromTeamId, setFromTeamId] = useState<string>(initialFromTeamId || '')
  const [toTeamId, setToTeamId] = useState<string>(initialToTeamId || '')

  const handleParticipantChange = (e: any) => {
    setParticipantId(e.target.value)
  }

  const handleFromTeamChange = (e: any) => {
    setFromTeamId(e.target.value)
  }

  const handleToTeamChange = (e: any) => {
    setToTeamId(e.target.value)
  }

  const onClose = () => {
    setParticipantId('')
    setToTeamId('')
    setFromTeamId('')
    setModal(false)
  }

  useEffect(() => {
    if (participantId) {
      fetchParticipantRegistrations(participantId)
    }
  }, [participantId])

  useEffect(() => {
    if (allTeams && playerRegistrations) {
      const pt = allTeams.filter(
        t =>
          playerRegistrations.map((r: any) => r.teamId._id).includes(t._id) &&
          playerRegistrations
            .map((r: any) => r.season._id)
            .includes(t.season._id),
      )
      if (pt.length === 1) {
        setFromTeamId(pt[0]._id)
      }
      setPlayerTeams(pt)
    }
  }, [allTeams, playerRegistrations])

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalBody>
          <p id="transition-modal-title" className="montserrat bold">
            Transfer player to team
          </p>
          <p id="transition-modal-description" className="montserrat">
            Select a player and team to transfer that player from their current
            team to a new team
          </p>
          <div style={{ paddingBottom: '15px' }}>
            <FormControl
              variant="filled"
              // className={classes.formControl}
            >
              <InputLabel shrink htmlFor="filled-homeTeam-native-simple">
                Player
              </InputLabel>
              <Select
                native
                sx={{ minWidth: '300px', marginRight: '1rem' }}
                value={participantId}
                onChange={handleParticipantChange}
                inputProps={{
                  name: 'participant',
                  id: 'filled-participant-native-simple',
                  tabIndex: 1,
                }}
              >
                <option key="" value="">
                  None
                </option>
                {_.sortBy(participants || [], ['firstName', 'lastName']).map(
                  (p: Participant) => (
                    <option key={p._id} value={p._id}>
                      {`${p.firstName} ${p.lastName}`}
                    </option>
                  ),
                )}
              </Select>
            </FormControl>
          </div>
          <div style={{ paddingBottom: '15px' }}>
            <FormControl
              variant="filled"
              // className={classes.formControl}
            >
              <InputLabel shrink htmlFor="filled-fromTeam-native-simple">
                Current Team
              </InputLabel>
              <Select
                native
                sx={{ minWidth: '300px', marginRight: '1rem' }}
                value={fromTeamId}
                onChange={handleFromTeamChange}
                inputProps={{
                  name: 'fromTeam',
                  id: 'filled-team-native-simple',
                  tabIndex: 1,
                }}
              >
                <option key="" value="">
                  None
                </option>
                {_.sortBy(playerTeams || [], ['name']).map((t: Team) => (
                  <option key={t._id} value={t._id}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ paddingBottom: '15px' }}>
            <FormControl
              variant="filled"
              // className={classes.formControl}
            >
              <InputLabel shrink htmlFor="filled-toTeam-native-simple">
                New Team
              </InputLabel>
              <Select
                native
                sx={{ minWidth: '300px', marginRight: '1rem' }}
                value={toTeamId}
                onChange={handleToTeamChange}
                inputProps={{
                  name: 'team',
                  id: 'filled-team-native-simple',
                  tabIndex: 1,
                }}
              >
                <option key="" value="">
                  None
                </option>
                {_.sortBy(allTeams || [], ['name']).map((t: Team) => (
                  <option key={t._id} value={t._id}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ float: 'right', marginTop: '2.5rem' }}>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={onClose}
              style={{
                marginRight: '1rem',
                background: 'white',
                color: '#000',
                border: '1px solid #008174',
              }}
            >
              Cancel
            </StyledButton>
            {isLoading && <Spinner size={22} />}
            {!isLoading && (
              <StyledButton
                disabled={isLoading}
                variant="contained"
                color="primary"
                className="montserrat"
                onClick={async () => {
                  if (!participantId || !fromTeamId || !toTeamId) {
                    return
                  }
                  await transferParticipantToTeam({
                    fromTeamId,
                    participantId,
                    toTeamId,
                  }).unwrap()
                  onClose()
                }}
                style={{
                  padding: '0.4rem 1.5rem 0.4rem 1.5rem',
                }}
              >
                Transfer Player
              </StyledButton>
            )}
          </div>
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

ParticipantTeamTransferModal.defaultProps = {
  initialParticipantId: undefined,
  initialFromTeamId: undefined,
  initialToTeamId: undefined,
}

export default ParticipantTeamTransferModal
